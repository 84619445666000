<template>
  <main class="main">
    <div class="unauthenticated">
      <img src="@/assets/img/logo.png" alt="" class="logo" />

      <div class="login-dialog">
        <slot></slot>
      </div>
    </div>
  </main>
</template>

<style lang="scss" scoped>
@import "@/assets/css/variables";
.unauthenticated {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  justify-items: center;
  height: 100vh;
}

.logo {
  display: block;
  height: 100px;
  margin-bottom: 1rem;
}
.login-dialog {
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
  background-color: white;
  display: block;
  width: 80%;
  max-width: 500px;
  padding: 1.5rem;
  border-radius: $border-radius;
}
</style>
