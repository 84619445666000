<template>
  <Unauthenticated>
    <h1>{{ $t("Login") }}</h1>

    <div class="form">
      <div class="field">
        <label for="username">{{ $t("Username") }}</label>
        <input type="text" name="username" v-model="username" />
      </div>
      <div class="field">
        <label for="password">{{ $t("Password") }}</label>
        <input type="password" name="password" v-model="password" />
      </div>
    </div>
    <div class="btn-bar">
      <div class="btn btn--primary" @click="login()">
        {{ $t("Login") }}
      </div>
    </div>
  </Unauthenticated>

  <td>
    <span style="background: #e41a1c; width: 63px"></span>
    <span style="background: #377eb8; width: 63px"></span>
    <span style="background: #4daf4a; width: 63px"></span>
    <span style="background: #984ea3; width: 63px"></span>
    <span style="background: #ff7f00; width: 63px"></span>
    <span style="background: #ffff33; width: 63px"></span>
    <span style="background: #a65628; width: 63px"></span>
    <span style="background: #f781bf; width: 63px"></span>
  </td>
</template>

<script>
import store from "../store";
import Unauthenticated from "./Unauthenticated.vue";

export default {
  methods: {
    login() {
      store.dispatch("doLogin", {
        username: this.username,
        password: this.password,
      });
    },
  },
  components: { Unauthenticated },
};
</script>
